import React from "react";
import Card from "react-bootstrap/Card";
import Img from "gatsby-image";
import Colors from "../../styles/Colors";

export default ({ cardData, activityCardStyle }) => {
  const cardStyle = {
    position: "relative",
    margin: "0px 0px 0.5rem 0px",
    display: "inline-flex",
    width: "100%",
    borderStyle: "solid",
    borderWidth: "0.2rem",
    borderColor: activityCardStyle.color
  };
  const cardBodyPlacement = buildCardBodyPlacement(activityCardStyle.placement);

  const cardBodyStyle = {
    position: "relative",
    maxWidth: "25rem",
    borderStyle: "solid",
    borderColor: activityCardStyle.color,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    ...cardBodyPlacement
  };

  const cardTextStyle = {
    color: Colors.white,
    textAlign: "justify"
  }

  const cardTitleStyle = {
    textAlign: "center",
    color: activityCardStyle.color
  }

  const imgStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    zIndex: "-150",
  }

  return (
    <Card className={"bg-transparent"} style={cardStyle}>
      <Card.Body style={cardBodyStyle}>
        <Card.Title style={cardTitleStyle}><h2>{cardData.title}</h2></Card.Title>
        <Card.Text style={cardTextStyle}>{cardData.description}</Card.Text>
      </Card.Body>
      <Img fluid={cardData.imageSharp.fluid} durationFadeIn={1500} style={imgStyle} />
    </Card>
  );
}

const buildCardBodyPlacement = (activityCardStylePlacement) => {
  if (activityCardStylePlacement === "right") {
    return ({
      marginLeft: "auto",
      right: "0",
      borderWidth: "0 0 0 0.1rem"
    })
  } else {
    return ({
      marginLeft: "0",
      right: "auto",
      borderWidth: "0 0.1rem 0 0"
    })
  }
};