import React from "react";
import ActivitiesCardList from "../components/activities/ActivitiesCardList";
import PageTitle from "../components/common/PageTitle";
import { useStaticQuery, graphql } from "gatsby";


export default (props) => {
  const data = useStaticQuery(graphql`
  query activitiesData {
    activities: allActivitiesYaml {
      nodes {
        description
        title
        imagePath
        language
      }
    }
  }`);

  return (
    <>
      <PageTitle {...props} />
      <ActivitiesCardList activitesListData={data.activities.nodes.filter(activity => activity.language === props.language)} />

    </>
  );
}