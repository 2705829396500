import React from "react";
import ActivityCard from "./ActivityCard";
import { useStaticQuery, graphql } from "gatsby";
import Colors from "../../styles/Colors";

function getImageSharp(allImages, relativePath) {
  const ImageEdge = allImages.images.edges.find(edge => edge.node.relativePath === relativePath);
  return (ImageEdge === undefined) ? null : ImageEdge.node.childImageSharp;
}

export default ({ activitesListData }) => {
  const allImages = useStaticQuery(graphql`
  query activityImagesQuery {
      images: allFile(filter: {relativeDirectory: {eq: "activitiesImg"}, extension: {in: ["jpg", "jpeg", "png"]}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
            relativePath
          }
        }
      }
    }
  `)
  let dataArray = [];
  let activityCardData = {};
  let listItems = null;
  if (Array.isArray(activitesListData) && activitesListData.length > 0) {
    activitesListData.forEach((activity) => {
      activityCardData = {
        title: activity.title,
        description: activity.description,
        imageSharp: getImageSharp(allImages, activity.imagePath)
      };
      dataArray.push(activityCardData);
    });
    listItems = dataArray.map((data, index) => {
      if (index % 2 === 0) {
        return (
          <ActivityCard cardData={data} activityCardStyle={{ color: Colors.yellow, placement: "left" }} />
        );
      } else {
        return (
          <ActivityCard cardData={data} activityCardStyle={{ color: Colors.blue, placement: "right" }} />
        );
      }
    });
  }
  return (
    <React.Fragment>
      {listItems}
    </React.Fragment>
  );
}