import React from "react";
import Row from "react-bootstrap/Row";

export default (props) => {
  const titleStyle = {
    marginTop: "10px",
    marginBottom: "20px"
  };

  const title = props.siteMetadata.menuLinks.find(element => element.link === props.pathname).name;

  return (
    <Row className={"justify-content-center"}>
      <h2 style={titleStyle}>{title}</h2>
    </Row>
  );
}